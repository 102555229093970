<template>
	<v-layout align-center row wrap>
		<v-flex offset-xs1 xs3>
			<v-text-field v-model="searchedText" clearable :label="$t('actions.search')" prepend-inner-icon="search" />
		</v-flex>
		<v-flex offset-xs4 xs3>
			<SelectWithChips
				v-model="selectedThemes"
				:items="themes"
				item-value="title"
				item-text="title"
				:label="$t('actions.filter')"
				:max-selected-items-showed="2"
				multiple
				prepend-inner-icon="filter_list"
			/>
		</v-flex>
	</v-layout>
</template>

<script>
import ThemesModuleGuard from '@/mixins/ModulesGuards/Themes/ThemesModuleGuard'

export default {
	name: 'ThemeAssignmentSectionHeader',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		})
	},
	mixins: [ThemesModuleGuard],
	computed: {
		searchedText: {
			get: function () {
				return this.service.teamManager.store.getSearchedText()
			},
			set: function (val) {
				this.service.teamManager.store.setSearchedText(val)
			}
		},
		selectedThemes: {
			get: function () {
				return this.service.teamManager.store.getSearchedThemes()
			},
			set: function (val) {
				this.service.teamManager.store.setSearchedThemes(val)
			}
		},
		themes: function () {
			return this.service.teamManager.store.getThemes()
		}
	}
}
</script>
